import { ReactElement } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { AnchorLink } from '../components/styles/container.styled';
import Heading from '../components/styles/heading.styled';
import { Section } from '../components/styles/section.styled';

const Contact = (): ReactElement => (
  <Layout>
    <Section>
      <Seo title="Contacts" />
      <Heading>Reach out to me! ✉️</Heading>
      <p>Do you think I can help your project? Be sure to le me know!</p>
      <p>
        <AnchorLink href="mailto:honza@honzapav.com">
          honza@honzapav.com
        </AnchorLink>
        <br />
        <AnchorLink href="tel:+420 724 666 236">+420 724 666 236</AnchorLink>
        <br />
        <AnchorLink href="https://twitter.com/honzapav">
          @honzapav on Twitter
        </AnchorLink>
        <br />
        <AnchorLink href="https://www.linkedin.com/in/honzapav/">
          Linkedin profile
        </AnchorLink>
      </p>
    </Section>
  </Layout>
);

export default Contact;
